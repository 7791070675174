<template>
    <header class="border-bottom pb-3">
        <nav class="navbar navbar-light px-0 ">
            <span class="text-secondary text-uppercase h6">Made with love by TBA!&reg;</span>
            <form class="form-inline">
                <a href="javascript::void();" class="text-secondary mr-0 h6 ml-2 text-decoration-none" @click="logout">Log
                    Out</a>
            </form>
        </nav>

        <div class="row m-0">
            <div class="col-md-2 pl-0 col-sm-12">
                <router-link :to="{ name: 'BlastList' }">
                    <img :src="publicURL + 'images/blastchat.png'" alt="" class="login-logo w-75">
                </router-link>
            </div>
        </div>
    </header>
</template>

<script>

import API from "../../../api/http";

export default {
    name: 'Header',
    data() {
        return {
            publicURL: process.env.VUE_APP_BASE_URL,
        }
    },
    methods: {
        logout() {
            API.logout().then(() => {
                this.$router.push({ name: 'Login' });
            });
        }
    }
}
</script>

