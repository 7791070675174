<template>
    <div id="app" :class="{'container p-0': true, 'admin-login': showLoginElements}">
        <!-- <Loading /> -->
        <Header v-if="showLoginElements" />

        <template name="fade" v-if="showLoginElements">
            <div class="row mt-3">
                <div class="col-12 col-md-2">
                    <SideMenu v-if="showLoginElements" />
                </div>
                <div class="col-12 col-md-10 border-left">
                    <router-view />
                </div>
            </div>
        </template>
        <template name="fade" mode="out-in" v-else>
            <router-view />
        </template>
    
    </div>
</template>

<script>
    import SideMenu from './components/Private/Shared/SideMenu';
    import Header from './components/Private/Shared/Header';
    export default {
        name: 'App',
        components: { SideMenu, Header },
        computed: {
            user() { return this.$store.getters['User/user']  },
            showLoginElements(){ 
                return this.user && this.user.role == 'admin'
            },
            currentRouteName() {
                return this.$route;
            }
        }
    }
</script>