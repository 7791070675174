import API from './api'
import store from '../store'

export default {

  authenticate (data) {
    return new Promise((resolve) => {
      API.post('login', data).then((response) => {
        if(response && response.data.user){
          if(response.data.user.role == 'admin'){
            response.data.user['token'] = response.data.token
            this.setUser(response.data.user);  
          }
          resolve(response.data.user)
        }
        resolve(false)
      }).catch((error) => {
        error;
      })
    })
  },
  logout (data) {
    return new Promise((resolve) => {
      API.post('logout', data).then( () => {
          store.dispatch('reset')
          resolve()
      }).catch(error => {
        store.commit('Message/SET_MESSAGE', {
          message: error.response.data.errors.join('<br>'),
          type: 'warning'
        })
      })
    })
  },
  setUser (user){
    if(user){
      store.commit('User/SET_LOGIN_STATUS', true)
    }else{
      store.commit('User/SET_LOGIN_STATUS', false)
    }
    store.commit('User/SET_USER', user)
  },
  validateToken () {
    return new Promise((resolve) => {
      // TO DO reject
      resolve()
    })
  },
  get(url){
    return new Promise((resolve, reject) => {
      API.get(url).then(response => {
        resolve(response.data)
      }).catch(error => {
        store.commit('Message/SET_MESSAGE', {
          message: error.response.data.errors.join('<br>'),
          type: 'warning'
        })
        reject(error)
      })
    })
  },
  post(url,data, header = {}){
    return new Promise((resolve, reject) => {
      
      API.post(url,data,{
        headers: header 
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        // store.commit('Message/SET_MESSAGE', {
        //   message: error.response.data.errors.join('<br>'),
        //   type: 'warning'
        // })
        reject(error)
      })
    })
  }
}
