<template>
    <!-- MENU SIDEBAR-->
    <div class="nav flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
        <router-link tag="button" class="nav-link mb-2 text-light font-weight-bolder btn btn-lg py-2 green"
            :to="{ name: 'BlastList' }">
            Blasts
        </router-link>
        <router-link tag="button" class="nav-link mb-2 text-light font-weight-bolder btn btn-lg py-2 purple"
            :to="{ name: 'UserList' }">
            Users
        </router-link>
        <router-link tag="button" class="nav-link mb-2 text-light font-weight-bolder btn btn-lg py-2 red"
            :to="{ name: 'Hastag' }">
            Tags
        </router-link>
        <router-link tag="button" class="nav-link mb-2 text-light font-weight-bolder btn btn-lg py-2 yellow"
            :to="{ name: 'QuizEdit' }">
            Quiz
        </router-link>
        <router-link tag="button" class="nav-link mb-2 text-light font-weight-bolder btn btn-lg py-2 pink"
            :to="{ name: 'SurveyList' }">
            Survey
        </router-link>
        <router-link tag="button" class="nav-link mb-2 text-light font-weight-bolder btn btn-lg py-2 blue"
            :to="{ name: 'FlickList' }">
            Vids
        </router-link>
        <router-link tag="button" class="nav-link mb-2 text-light font-weight-bolder btn btn-lg py-2 green"
            :to="{ name: 'Leaderboard' }">
            Leaderboard
        </router-link>
        <router-link tag="button" class="nav-link mb-2 text-light font-weight-bolder btn btn-lg py-2 purple"
            :to="{ name: 'ForumTags' }">
            Forum Tags
        </router-link>
        <router-link tag="button" class="nav-link mb-2 text-light font-weight-bolder btn btn-lg py-2 red"
            :to="{ name: 'ForumPosts' }">
            Forum Posts
        </router-link>
        <router-link tag="button" class="nav-link mb-2 text-light font-weight-bolder btn btn-lg py-2 yellow"
            :to="{ name: 'UserChatGroup' }">
            DAO Groups
        </router-link>
    </div>
    <!-- END MENU SIDEBAR-->
</template>

<script>
export default {
    name: 'SideMenu',
    computed: {
        user() {
            return this.$store.getters['User/user']
        }
    }
}
</script>

