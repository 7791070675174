const privateRoutes = [
    {
        path: '',
        redirect: '/blasts',
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/blasts',
        component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Blasts/Blasts'),
        // name: 'Blasts',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'BlastList',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Blasts/List'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/blasts/flagged',
                name: 'FlaggedList',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Blasts/FlaggedList'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/blasts/squad',
                name: 'SquadList',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Blasts/SquadList'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/blasts/hastag',
                name: 'HastagList',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Blasts/HastagList'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/blast',
                name: 'BlastAdd',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Blasts/Blast'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/blast/:public_id',
                name: 'BlastEdit',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Blasts/Blast'),
                meta: {
                    requiresAuth: true,
                },
            }
        ]
    },
    {
        path: '/quizzes',
        component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Quizzes/Quizzes'),
        // name: 'Quizzes',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'QuizList',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Quizzes/List'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/Quiz',
                name: 'QuizAdd',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Quizzes/Quiz'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/Quiz/1',
                name: 'QuizEdit',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Quizzes/Quiz'),
                meta: {
                    requiresAuth: true,
                },
            }
        ]
    },
    {
        path: '/surveys',
        component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Surveys/Surveys'),
        name: 'Surveys',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'SurveyList',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Surveys/List'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/survey',
                name: 'SurveyAdd',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Surveys/Survey'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/survey/:public_id',
                name: 'SurveyEdit',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Surveys/Survey'),
                meta: {
                    requiresAuth: true,
                },
            }
        ]
    },
    {
        path: '/users',
        component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Users/Users'),
        name: 'Users',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'UserList',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Users/List'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/user',
                name: 'UserAdd',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Users/User'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/user/:public_id',
                name: 'UserEdit',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Users/User'),
                meta: {
                    requiresAuth: true,
                },
            }
        ]
    },
    {
        path: '/chat-group',
        component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Chat-Group/UserChatGroup'),
        name: 'ChatGroup',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'user-chat-group',
                name: 'UserChatGroup',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Chat-Group/List'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: 'chat-group-edit/:user_chat_group_id',
                name: 'UserChatGroupEdit',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Chat-Group/UserChatGroupForm'),
                meta: {
                    requiresAuth: true,
                },
            }
        ]
    },
    {
        path: '/video-feeds',
        component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Flicks/Flicks'),
        name: 'Flicks',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'FlickList',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Flicks/List'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/video-feeds',
                name: 'FlickAdd',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Flicks/Flick'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/video-feeds/:public_id',
                name: 'FlickEdit',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Flicks/Flick'),
                meta: {
                    requiresAuth: true,
                },
            }
        ]
    },
    {
        path: '/hastag',
        component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Hastag/Hastag'),
        name: 'Hastag',
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/leaderboard',
        component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Leaderboard/Leaderboard'),
        name: 'Leaderboard',
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/sponsor',
        component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Leaderboard/Sponsor'),
        name: 'Sponsor',
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/forum-posts',
        component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Forum-Post/Forums.vue'),
        // name: 'Blasts',
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'ForumPosts',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Forum-Post/List.vue'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/forum/:public_id',
                name: 'ForumEdit',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Forum-Post/Forum'),
                meta: {
                    requiresAuth: true,
                },
            }
        ]
    },

    {
        path: '/forum-tags',
        name: 'Blasts',
        component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Tags/Forums'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: '',
                name: 'ForumTags',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Tags/List'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/tags-add',
                name: 'TagAdd',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Tags/TagForm'),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: '/tags-edit/:id',
                name: 'TagEdit',
                component: () => import(/* webpackChunkName: "privateRoutes" */ '../components/Private/Tags/TagForm'),
                meta: {
                    requiresAuth: true,
                },
            },
        ]
    },
    // {
    //   path: '/games',
    //   component: () => import(/* webpackChunkName: "privateRoutes" */ '../views/Private/Game/Index'),
    //   meta: {
    //     requiresAuth: true,
    //     module: 'games'
    //   },
    //   children: [
    //     {
    //       path: '/',
    //       name: 'GameList',
    //       component: () => import(/* webpackChunkName: "privateRoutes" */ '../views/Private/Game/List'),
    //       meta: {
    //         requiresAuth: true,
    //         module: 'games'
    //       },
    //     },
    //     {
    //       path: 'add',
    //       name: 'GameAdd',
    //       component: () => import(/* webpackChunkName: "privateRoutes" */ '../views/Private/Game/Game'),
    //       meta: {
    //         requiresAuth: true,
    //         module: 'games'
    //       },
    //     },
    //     {
    //       path: 'edit/:id',
    //       name: 'GameEdit',
    //       component: () => import(/* webpackChunkName: "privateRoutes" */ '../views/Private/Game/Game'),
    //       meta: {
    //         requiresAuth: true,
    //         module: 'games'
    //       },
    //     }
    //   ]
    // }
]
export default privateRoutes
