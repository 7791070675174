import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import { FontAwesomeIcon } from './helper/FontAwesome'

// Import Bootstrap
import 'bootstrap'


import './assets/scss/style.scss'


const app = createApp(App)
app.use(router)
app.use(store)
app.use(Toast)
app.component('fa', FontAwesomeIcon);
app.mount('#app')